// ProductCard.js
import React, { useState } from "react";
import ImageSlider from "./ProductCard/ImageSlider";
import "./ProductCard.css";

const ProductCard = ({ product, setearCarrito }) => {
  const [counts, setCounts] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [pedidoAEnviar, setPedidoAEnviar] = useState({
    [product.nombre]: { sabor: {} },
  });

  const handleAddToCart = (sabor) => {
    console.log(counts);
    console.log(pedidoAEnviar)
    setCounts((prevCounts) => (
      {
      ...prevCounts,
      [sabor]: (prevCounts[sabor] || 0) + 1,
    }));
    setPedidoAEnviar((prevPedido) => {
      const newPedido = { ...prevPedido };
      newPedido[product.nombre].sabor[sabor] =
        (newPedido[product.nombre].sabor[sabor] || 0) + 1;
      newPedido[product.nombre].total =
        Object.values(newPedido[product.nombre].sabor).reduce(
          (a, b) => (a + b),
          0
        ) * product.precio;
      return newPedido;
    });
  };
  

  const handleRemoveFromCart = (sabor) => {
    console.log(counts);
    setCounts((prevCounts) => {
      const newCounts = {
        ...prevCounts,
        [sabor]: prevCounts[sabor] > 0 ? prevCounts[sabor] - 1 : 0,
      };
      if (newCounts[sabor] === 0) {
        delete newCounts[sabor];
      }
      return newCounts;
    });

    setPedidoAEnviar((prevPedido) => {
      const newPedido = { ...prevPedido };
      newPedido[product.nombre].sabor[sabor] =
        newPedido[product.nombre].sabor[sabor] > 0
          ? newPedido[product.nombre].sabor[sabor] - 1
          : 0;
      if (newPedido[product.nombre].sabor[sabor] === 0) {
        delete newPedido[product.nombre].sabor[sabor];
      }
      newPedido[product.nombre].total =
        Object.values(newPedido[product.nombre].sabor).reduce(
          (a, b) => a + b,
          0
        ) * product.precio;
      return newPedido;
    });
  };

  var enviandoAndo = () => {
    setearCarrito(pedidoAEnviar);
    setShowModal(false);
  };

  const buttonClasses = `bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded shadow-md text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl self-center cursor-pointer transition-all rounded-lg border-blue-600 border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]
  `;

  const images = product.imagen.split(",").map(s => s.trim());

  return (
    <div className="lg:mx-6 lg:my-1 -my-2 ">
      <div className="productCard mx-auto w-full relative max-w-lg overflow-hidden  m-1 mt-10 transform transition duration-500 ease-in-out hover:scale-105 h-200 z-30">
      <ImageSlider images={images} description={product.descripcion} />

      <div className="w-full flex flex-col justify-between items-start px-0 space-y-2 sm:space-y-0 sm:space-x-4">
        <div className="w-full">
          <div className="nombreProducto font-bold text-xs lg:text-sm lg:pl-4 pl-2 text-left flex items-center justify-between h-10">
            {product.nombre}
            <span className="mr-1 flex justify-center align-middle items-center text-base relative right-0 bg-amber-400 rounded-full shadow-black h-11 w-11 lg:h-14 lg:w-14">
              S/{product.precio}
            </span>
          </div>
          

          <div className="w-full flex justify-evenly items-center">
            <div className="w-full lg:flex flex-col lg:flex-row justify-center items-center">
              
              {Object.keys(counts).length === 0 ? (
                <button
                  onClick={() => setShowModal(true)}
                  style={{ fontSize: "12px" }}
                  className={`${buttonClasses} w-[40vw] mx-1 text-sm my-2 py-2`}
                >
                  Añadir al carrito
                </button>
              ) : (
                <button
                  onClick={() => setShowModal(true)}
                  className={`${buttonClasses} w-[40vw] mx-1 text-sm my-2 py-2`}
                  style={{
                    background: "#2ecc31",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  Modificar pedido
                </button>
              )}
            </div>

            {showModal && (
              <div className="bg-fuchsia-50 lg:p-4 p-1 rounded shadow-lg font-roboto absolute z-20 inset-0 flex flex-col items-center justify-center">
                <button
                  onClick={() => setShowModal(false)}
                  className={`${buttonClasses} bg-blue-700/80 absolute lg:top-2 top-0.5 lg:right-2 right-0.5 bg-red border-none lg:text-lg text-xs bg-red-500 text-white rounded-full lg:p-1 p-0`}
                >
                  X
                </button>
                <h2 className="lg:text-2xl text-xs font-bold lg:mt-4 lg:mb-4 mt-5 mb-1 text-fuchsia-900/90">{product.nombre}</h2>
                <hr className="lg:mb-1 mb-0" />
                {product.sabor.split(",").map(s => s.trim()).map((sabor, index) => (
                  <div
                    key={index}
                    className="conteinerSabores lg:mb-4 mb-1 bg-blue-950/15 lg:px-4 px-0.5 lg:py-2 py-2 rounded-lg shadow-md w-full overflow-hidden flex justify-between items-center"
                  >
                    <p className="lg:ml-2 ml-2 text-fuchsia-700/80 font-semibold lg:mx-4 ">{sabor.toUpperCase()}</p> 
                    <div className="pedidos">
                      <button
                        className={`botonera`}
                        
                        onClick={() => handleRemoveFromCart(sabor)}
                      >
                        -
                      </button>
                      <span
                      className="text-white lg:text-sm text-xs font-bold text-black/40 lg:mx-3 mx-1">
                        {counts[sabor] || 0}
                        </span>
                      <button
                        className={`botonera `}
                        
                        onClick={() => handleAddToCart(sabor)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                ))}
                <h3 className="text-xs text-fuchsia-800/90 lg:text-2xl font-bold lg:mb-4 mb-2">Total: S/{pedidoAEnviar[product.nombre].total}</h3>
                <button
                  onClick={() => enviandoAndo()}
                  className="botonEnviar lg:px-4 px-2 lg:py-2 py-1 text-xs lg:text-lg text-white"
                >
                  Añadir pedido
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ProductCard;
